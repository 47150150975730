
frappe.provide('frappe.sandbox.component');

var viewer_file = null;

function OnModelLoaded(model) {
	
	let calculationDiv = document.getElementById('calculation_result');
	let boundingBox = OV.GetBoundingBox(model);
	calculationDiv.innerHTML =
		'Surface area: ' + OV.CalculateSurfaceArea(model).toFixed(2) + '<br>' +
		'Volume: ' + OV.CalculateVolume(model).toFixed(2) + '<br>' +
		'Size: (' +
		(boundingBox.max.x - boundingBox.min.x) + ', ' +
		(boundingBox.max.y - boundingBox.min.y) + ', ' +
		(boundingBox.max.z - boundingBox.min.z) +
		')<br>';
}

function print_file() {
	let img = document.createElement ('img');
    img.src = viewer_file.GetImageAsDataUrl (400, 300, false);
    
	let element = document.querySelector('#imgs_snapshot');
	element.appendChild(img);
}

function render_file_3d(paths) {
	let parentDiv = document.getElementById('viewer');

	OV.SetExternalLibLocation ('assets/sandbox/js/o3dv/libs');

	var viewer = new OV.EmbeddedViewer(parentDiv, {
		onModelLoaded: async () => {
			viewer_file = viewer.GetViewer()
			
			await OnModelLoaded(viewer.GetModel())
		}
	});
	
	viewer.LoadModelFromUrlList(paths);
}

function render3d(paths) {
		frappe.require('assets/sandbox/js/o3dv/o3dv.min.js', () => { render_file_3d(paths) })

		$('#pesquisa').on('click', () => {
			print_file()
		})	
}
	

frappe.sandbox.component.render_3d = render3d
